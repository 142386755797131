var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.permissoes
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "m-1 mr-4 px-0 cadastrar-novo-corretor" },
            [
              _c("Breadcrumb", {
                attrs: {
                  title: "Corretores",
                  actualPage: "Cadastrar novo corretor",
                  previousPage: "corretores",
                  previousPageTitle: "Lista de Corretores"
                }
              })
            ],
            1
          ),
          [
            _c(
              "form-wizard",
              {
                staticClass: "cadastrar-novo-corretor",
                attrs: {
                  color: "rgba(var(--vs-warning), 1)",
                  title: null,
                  subtitle: null,
                  finishButtonText: "Submit"
                },
                on: { "on-complete": _vm.concluirCadastro },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "footer",
                      fn: function(props) {
                        return [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "wizard-footer-left" },
                              [
                                props.activeTabIndex > 1
                                  ? _c(
                                      "vs-button",
                                      {
                                        attrs: { color: "primary" },
                                        nativeOn: {
                                          click: function($event) {
                                            return props.prevTab()
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "onpoint-arrow-left icon-font"
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2"
                                          },
                                          [_vm._v("Voltar")]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "wizard-footer-right" },
                              [
                                props.activeTabIndex === 0
                                  ? _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          disabled: !_vm.habilitarBtnIniciarCadastro,
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.iniciarCadastro(props)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Iniciar cadastro\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "vs-button",
                                      {
                                        attrs: { color: "primary" },
                                        nativeOn: {
                                          click: function($event) {
                                            _vm.avancarPagina(props)
                                              ? props.nextTab()
                                              : ""
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  props.isLastStep
                                                    ? "Concluir cadastro"
                                                    : "Proxima etapa"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        !props.isLastStep
                                          ? _c("i", {
                                              staticClass:
                                                "onpoint-arrow-right icon-font"
                                            })
                                          : _vm._e()
                                      ]
                                    )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4487968
                )
              },
              [
                _c(
                  "tab-content",
                  { staticClass: "mb-8 mt-8" },
                  [
                    _c("CadastroCorretoraCNPJ", {
                      attrs: {
                        cnpjCorretora: _vm.cnpjCorretora,
                        exibirModalEmailRecuperacaoSenha:
                          _vm.exibirModalEmailRecuperacaoSenha
                      },
                      on: {
                        atualizarCnpjCorretora: _vm.atualizarCnpjCorretora,
                        atualizarModalEmailRecuperacaoSenha:
                          _vm.atualizarModalEmailRecuperacaoSenha
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "tab-content",
                  { staticClass: "mb-8 mt-8" },
                  [
                    _c("DadosCorretoraAdmPortal", {
                      attrs: {
                        podeAlterarCodigoCorretor:
                          _vm.podeAlterarCodigoCorretor,
                        podeAlterarCodigoSucursal:
                          _vm.podeAlterarCodigoSucursal,
                        podeAlterarCodigoContrato:
                          _vm.podeAlterarCodigoContrato,
                        tiposDeCanais: _vm.tiposDeCanais,
                        podeAlterarCanal: _vm.podeAlterarCanal,
                        dadosCorretora: _vm.dadosCorretora,
                        dadosComplementaresCorretora:
                          _vm.dadosComplementaresCorretora
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "tab-content",
                  { staticClass: "mb-8 mt-8" },
                  [
                    _c("DadosBancariosRespFinanceiro", {
                      attrs: {
                        dadosCorretora: _vm.dadosCorretora,
                        bancos: _vm.bancos,
                        tiposDeContas: _vm.tiposDeContas
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "tab-content",
                  { staticClass: "mb-8 mt-8" },
                  [
                    _c("DadosUsuario", {
                      attrs: { dadosCorretora: _vm.dadosCorretora }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }