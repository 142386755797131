import { render, staticRenderFns } from "./DadosCorretoraAdmPortal.vue?vue&type=template&id=230b1086&"
import script from "./DadosCorretoraAdmPortal.vue?vue&type=script&lang=js&"
export * from "./DadosCorretoraAdmPortal.vue?vue&type=script&lang=js&"
import style0 from "./DadosCorretoraAdmPortal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('230b1086')) {
      api.createRecord('230b1086', component.options)
    } else {
      api.reload('230b1086', component.options)
    }
    module.hot.accept("./DadosCorretoraAdmPortal.vue?vue&type=template&id=230b1086&", function () {
      api.rerender('230b1086', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cadastro-corretor-seguradora/components/DadosCorretoraAdmPortal.vue"
export default component.exports